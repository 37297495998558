import React from 'react';

const HTML = () => {
  return (
    <div className='mx-auto pb-14 w-4/5'>
      <h1 className='my-3 text-4xl'>HTML Introduction</h1>
      <p>
        Tim Berners-Lee developed HTML (HyperText Markup Language) as a standard
        for building web pages in 1991. It is a markup language that defines
        elements such as headings, paragraphs, links, and images and is used to
        organize material on the web. The foundation of web content is HTML. To
        put it simply, HTML is similar to a website's framework. It is a
        collection of guidelines that directs a web browser how to present other
        elements on a webpage, such as photos, videos, and text. Consider it the
        building blocks that, like bricks and mortar, give a house its structure
        and appearance. That is how it should be viewed.
      </p>
      <h4 className='my-3 text-lg'>To put it briefly:</h4>
      <p>
        The language of the web, HTML, is used to build webpages. The basic
        structure or design of web pages that we view on the Internet is defined
        by HTML. HTML files usually have an extension of ".html" or ".htm". HTML
        is made up of a collection of tags contained in an HTML document. HTML
        is available in multiple versions, the most recent of which being HTML5.
      </p>
      <h3 className='my-3 text-3xl'>Features of HTML</h3>
      <p>
        It is independent of platforms. Chrome maintains consistency in page
        display across many operating systems, including Windows, Linux, and
        Mac. A web page can have audio, video, and image additions (YouTube, for
        instance, displays videos on its website). Not a programming language,
        but a markup language is HTML. It can display interactive, or dynamic,
        web pages by integrating it with other languages like CSS, JavaScript,
        etc.
      </p>
      <h3 className='my-3 text-3xl'>
        Why the Term HyperText & Markup Language?
      </h3>
      <p>
        The two primary terms that make up the term "Hypertext Markup Language"
        are "markup language" and "hypertext." 'Markup language' is a language
        that makes use of a particular collection of tags, whereas 'hypertext'
        is the connecting of text with other documents. Therefore, HTML is the
        process of employing specific tags to display text, pictures, music,
        video, etc. in a particular way. Remark: Tags, such as '{'<...>'}', are
        meaningful text contained in angle braces. Take the {'<Head>'} tag, for
        instance. Every tag has a distinct purpose and meaning when creating an
        HTML page, and it can have a variety of effects.
      </p>
      <h3 className='my-3 text-3xl'>History of HTML:</h3>
      <p>
        Tim Berners-Lee developed the first iteration of HTML in 1991 and
        founded the World Wide Web (www) in 1989. Additional effort was done to
        build and improve several versions of HTML between 1995 and 1997. A
        committee was established in 1999 to standardize HTML 4.0, which is
        still widely used today. HTML5, or version 5, is the most recent and
        stable version of the HTML language.
      </p>
    </div>
  );
};

export default HTML;
