import React from 'react';
import {Link} from 'react-router-dom';
import NoInternetImg from './nointernet.jpeg';

const NoInternet = () => {
  return (
    <div
      className='relative h-screen bg-slate-900/100 text-center'
      style={{
        backgroundImage: `url(${NoInternetImg})`,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}>
      <button className='absolute bottom-1 rounded-xl bg-blue-500 px-3 py-2 text-2xl text-white'>
        Refresh
      </button>
    </div>
  );
};

export default NoInternet;
