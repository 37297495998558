import React, {useState, useEffect} from 'react';
import {collection, getDocs} from 'firebase/firestore';
import {db} from '../API/firebase.config';
import './Resources.css';

const ResourceTemplate = ({url}) => {
  const [resource, setResourceInfo] = useState([]);

  const fetchPost = async () => {
    await getDocs(collection(db, url)).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setResourceInfo(newData);
    });
  };

  useEffect(() => {
    fetchPost();
  }, []);

  const getLinks = (item, index) => {
    return (
      <div className='ml-5 text-lg' key={index + 1}>
        <a href={item.url} target='_blank' rel='noreferrer'>
          {index + 1}) {item.name}
        </a>
      </div>
    );
  };

  const getBooks = (items, index) => {
    return (
      <details
        className='m-2 rounded-xl border border-blue-500 bg-slate-400/20 p-5'
        key={index}>
        <summary className='flex justify-between overflow-hidden whitespace-nowrap text-2xl font-semibold text-blue-600'>
          {items.title}
          <div className='w-5'></div>
        </summary>
        <div className='my-4 w-full border border-blue-700'></div>
        {items.link.map(getLinks)}
      </details>
    );
  };

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
      {resource.map(getBooks)}
    </div>
  );
};

export default ResourceTemplate;
