import {collection, getDocs} from 'firebase/firestore';
import React, {useEffect, useState} from 'react';
import {db} from '../../API/firebase.config';
import CourseTemplate from '../../Template/CourseTemplate';

const Certifications = () => {
  const [certificationInfo, setCertificationInfo] = useState([]);

  const fetchPost = async () => {
    await getDocs(collection(db, 'courseInfo')).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setCertificationInfo(newData);
    });
  };

  useEffect(() => {
    fetchPost();
  }, []);

  return <CourseTemplate course={certificationInfo} />;
};

export default Certifications;
