import React from 'react';
import {Link} from 'react-router-dom';
import lostCover from './404_page_cover.jpg';

const NotFound = () => {
  return (
    <div
      className='relative h-[400px] w-full pb-32 pt-6 text-center text-2xl underline sm:h-[500px] md:h-[700px] lg:h-[850px]'
      style={{
        backgroundImage: `url(${lostCover})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}>
      <div className='absolute bottom-6 w-full text-center'>
        <Link
          to='/'
          className='rounded-3xl border-2 border-blue-700 p-3 text-2xl text-blue-700 hover:bg-blue-700 hover:text-white'>
          Go to Home Page{' '}
          <i className='fa-solid fa-arrow-up-right-from-square fa-2xs' />
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
