import React from 'react';
import {Outlet, NavLink} from 'react-router-dom';

const Resources = () => {
  const resourcesLink = [
    {
      name: 'Books',
      url: '/resources/books',
    },
    {
      name: 'Question Paper',
      url: '/resources/questionpaper',
    },
  ];

  function navGen(items, index) {
    return (
      <NavLink
        to={items.url}
        key={index}
        className={({isActive}) =>
          `${
            isActive ? 'underline' : ''
          } mx-auto whitespace-nowrap p-2 text-3xl font-bold text-blue-700 hover:text-blue-900 hover:underline`
        }>
        {items.name}
      </NavLink>
    );
  }

  return (
    <div className='rounded-xl bg-slate-100/50 p-10'>
      <ul className='flex flex-col sm:flex-row'>{resourcesLink.map(navGen)}</ul>
      <div className='mb-6 mt-2 w-full border border-blue-700'></div>
      <Outlet />
    </div>
  );
};

export default Resources;
