import React from 'react';
import {NavLink, Outlet} from 'react-router-dom';

const Courses = () => {
  const NavBar = [
    {title: 'Youtube', url: '/courses/youtube'},
    {title: 'Certifications', url: '/courses/certifications'},
  ];
  return (
    <div className='bg-slate-100 pt-10 text-center'>
      <div className='text-4xl'>
        Pick a <span className='text-[#ff6e65]'>Platform</span> to{' '}
        <span className='text-[#ff6e65]'> Get Started </span>
        <div className='ml-[45%] mt-2 w-1/12 rounded-full border border-slate-500'></div>
        <div className='ml-[48%] mt-1 w-1/12 rounded-full border border-slate-500'></div>
      </div>
      <div className='mx-auto my-3 flex flex-row'>
        {NavBar.map((items, index) => {
          return (
            <NavLink
              to={items.url}
              key={index}
              className={({isActive}) =>
                `${
                  isActive ? 'underline' : ''
                } mx-auto whitespace-nowrap p-2 text-3xl font-bold text-blue-700 hover:text-blue-900 hover:underline`
              }>
              {items.title}
            </NavLink>
          );
        })}
      </div>
      <div className='mx-auto my-5 w-10/12 border border-blue-700'></div>

      <Outlet />
    </div>
  );
};

export default Courses;
