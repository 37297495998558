import React from 'react';
import Banner from './Banner';
import Cards from './Cards';
import Homecourses from './Homecourses';
import Welcome from './Welcome';

const MainBody = () => {
  return (
    <div>
      <Banner />
      <Cards />
      <Welcome />
      <Homecourses />
    </div>
  );
};

export default MainBody;
