import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';

const Header = () => {
  const [navStyle, setnavStyle] = useState('hidden');
  const [navIcon, setnavIcon] = useState('fa-bars');

  const displayNav = () => {
    if (navStyle === 'hidden') {
      setnavStyle('');
      setnavIcon('fa-xmark');
    } else {
      setnavStyle('hidden');
      setnavIcon('fa-bars');
    }
  };

  const navLink = [
    {id: 'Home', link: '/'},
    {id: 'Courses', link: '/courses'},
    {id: 'Subject', link: '/subject'},
    {id: 'Resources', link: '/resources'},
    {id: 'About Us', link: '/aboutus'},
  ];

  function navGen(items, index) {
    return (
      <div className='mx-auto py-2 md:mx-2' key={index}>
        <NavLink
          to={items.link}
          className={({isActive}) =>
            `${
              isActive ? 'text-orange-600' : 'text-blue-600'
            } whitespace-nowrap hover:text-blue-800 hover:underline hover:shadow-2xl`
          }>
          {items.id}
        </NavLink>
      </div>
    );
  }

  return (
    <div className='fixed left-0 right-0 top-0 z-10'>
      <div className='w-full bg-white p-2 font-semibold md:flex md:justify-between'>
        <div className='flex w-full items-center justify-between text-blue-800'>
          <div>
            <Link to='/' className='text-3xl'>
              EdLearn
            </Link>
          </div>

          <div className='md:hidden'>
            <button
              onClick={displayNav}
              className='m-1 rounded border-2 border-blue-800 p-1'>
              <i className={`fa-solid ${navIcon} fa-xl`}></i>
            </button>
          </div>
        </div>

        <ul
          className={`mx-2 flex flex-col items-center text-xl md:flex md:flex-row ${navStyle}`}>
          {navLink.map(navGen)}
        </ul>
      </div>
      <div className='w-full border border-blue-700'></div>
    </div>
  );
};

export default Header;
