import React from 'react';
import {Link} from 'react-router-dom';

const Cards = () => {
  const CardBlock = [
    {
      name: 'Engineering & Architecture',
      url: '/courses',
      style: 'rounded-lg bg-blue-600 hover:shadow-xl hover:shadow-cyan-900',
      iconStyle: 'fa-solid fa-gear fa-spin fa-2xl',
    },
    {
      name: 'Learning Resources',
      url: '/resources',
      style: 'rounded-lg bg-yellow-300 hover:shadow-xl hover:shadow-yellow-800',
      iconStyle: 'fa-solid fa-book-open fa-fade fa-2xl',
    },
    {
      name: 'Developer Roadmaps',
      url: 'https://roadmap.sh/',
      style: 'rounded-lg bg-pink-600 hover:shadow-xl hover:shadow-pink-900',
      iconStyle: 'fa-solid fa-mug-hot fa-beat fa-2xl',
    },
    {
      name: 'Soon..',
      url: '/',
      style: 'rounded-lg bg-green-400 hover:shadow-xl hover:shadow-green-900',
      iconStyle: 'fa-solid fa-code fa-beat fa-2xl',
    },
  ];
  return (
    <div className='grid grid-cols-1 gap-4 px-4 sm:grid-cols-2 lg:mx-auto lg:w-11/12 lg:grid-cols-4'>
      {CardBlock.map((items, index) => {
        return (
          <Link to={items.url} key={index} className={items.style}>
            <div className='flex flex-col py-16 text-center text-2xl text-white'>
              <i className={items.iconStyle}></i>
              <br />
              <div className='mt-5'>{items.name}</div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default Cards;
