import React, {useEffect, useState} from 'react';
import {collection, getDocs} from 'firebase/firestore';
import {db} from '../API/firebase.config';
import CourseTemplate from '../Template/CourseTemplate';

const Homecourses = () => {
  const [homeCourseInfo, setHomeCourseInfo] = useState([]);

  const fetchPost = async () => {
    await getDocs(collection(db, 'courseInfo')).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      const shuffle = (arr) => {
        return arr.sort(() => Math.random() - 0.5);
      };

      const latestData = shuffle([...newData]).slice(0, 6);
      setHomeCourseInfo(latestData);
    });
  };

  useEffect(() => {
    fetchPost();
  }, []);

  return (
    <div className='bg-slate-100 pt-10 text-center'>
      <div className='text-4xl'>
        Pick a<span className='text-[#ff6e65]'> Platform </span>to
        <span className='text-[#ff6e65]'> Get Started </span>
        <div className='ml-[45%] mt-2 w-1/12 rounded-full border border-slate-500'></div>
        <div className='ml-[48%] mt-1 w-1/12 rounded-full border border-slate-500'></div>
      </div>
      <CourseTemplate course={homeCourseInfo} />
    </div>
  );
};

export default Homecourses;
