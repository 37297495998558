import {Link} from 'react-router-dom';

const Footer = () => {
  const scrollToTop = () => {
    window.scroll(0, 0);
  };

  return (
    <div>
      <div className='grid grid-cols-1 bg-slate-900 p-20 font-light text-white md:grid-cols-2 lg:grid-cols-4'>
        <section className='my-5 w-3/4'>
          <div className='font-bold'>QUICK LINKS</div>
          <div className='my-2 border border-slate-700'></div>
          <ul className='my-4'>
            <li className='mb-2'>
              <i className='fa-solid fa-angles-right fa-xs'></i>
              <Link to='/' onClick={scrollToTop}>
                {' '}
                Home
              </Link>
            </li>
            <li className='mb-2'>
              <i className='fa-solid fa-angles-right fa-xs'></i>
              <Link to='/aboutus' onClick={scrollToTop}>
                {' '}
                About Us
              </Link>
            </li>
            <li className='mb-2'>
              <i className='fa-solid fa-angles-right fa-xs'></i>
              <Link to='/courses' onClick={scrollToTop}>
                {' '}
                Courses
              </Link>
            </li>
          </ul>
        </section>

        <section className='my-5 w-3/4'>
          <div className='font-bold'>CONTACT</div>
          <div className='my-2 border border-slate-700'></div>
          <ul className='my-4'>
            <li className='mb-4'>
              <i className='fa-regular fa-envelope'></i>
              <a href='mailto:kaushal2016b@gmail.com'>
                {' '}
                kaushal2016b@gmail.com
              </a>
            </li>
            <li className='mb-4'>
              <i className='fa-solid fa-phone'></i>
              <a href='tel:+917065965140'> +91 706-596-5140</a>
            </li>
            <li className='mb-4'>
              <i className='fa-solid fa-location-dot'></i>
              <div className='inline'> Sadarpur, Sec-45, Noida, 201301</div>
            </li>
          </ul>
        </section>

        <section className='my-5 w-3/4 md:col-span-2'>
          <div className='font-bold'>ABOUT</div>
          <div className='my-2 border border-slate-700'></div>
          <div className='my-5'>
            This Website will provide you the best Courses and Resources from
            the Internet for most of the topic on the Internet.
          </div>
        </section>
      </div>

      <section className='bg-slate-950 p-5 text-center text-white'>
        @2023 Ed-Learn. All rights reserved. Design by
        <a
          href='https://www.linkedin.com/in/kaushal-gupta-1049/'
          target='_blank'
          rel='noreferrer'
          className='hover:text-lg hover:underline'>
          {' '}
          KaushalGupta1049
        </a>
      </section>
    </div>
  );
};

export default Footer;
