import React from 'react';
import {Link, Outlet} from 'react-router-dom';

export default function subjectApp() {
  const subjectList = [
    {
      subjectName: 'HTML',
      address: '/subject/html',
    },
    {
      subjectName: 'CSS',
      address: '/subject/css',
    },
    {
      subjectName: 'JavaScript',
      address: '/subject/js',
    },
    // {
    //   subjectName: 'React',
    //   address: '/subject/react',
    // },
    // {
    //   subjectName: 'Express',
    //   address: '/subject/express',
    // },
    // {
    //   subjectName: 'MongoDB',
    //   address: '/subject/mongodb',
    // },
    // {
    //   subjectName: 'C',
    //   address: '/subject/c',
    // },
    // {
    //   subjectName: 'C++',
    //   address: '/subject/c++',
    // },
    // {
    //   subjectName: 'Python',
    //   address: '/subject/python',
    // },
    // {
    //   subjectName: 'Java',
    //   address: '/subject/java',
    // },
  ];

  const genSubjectLink = (items, index) => {
    return (
      <Link
        to={items.address}
        className='mx-2 inline-block text-xl font-bold text-purple-500'
        key={index}>
        {items.subjectName}
      </Link>
    );
  };

  return (
    <div>
      <div className='no-scrollbar mx-auto mb-10 mt-20 flex w-4/5 flex-row items-center overflow-x-auto rounded-xl bg-purple-200/30 p-2'>
        {subjectList.map(genSubjectLink)}
      </div>
      <Outlet />
    </div>
  );
}
