import React from 'react';
import bannerInfo from './Database/bannerInfo';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './banner.css';

const Banner = () => {
  const bannerContent = (items, index) => {
    return (
      <div className='font-semibold' key={index}>
        <img
          src={items.src}
          className='h-80 w-full object-cover sm:h-96 md:h-[450px] lg:h-[625px] xl:h-[800px]'
          alt='banner'
        />

        <div className='absolute top-0 h-full w-full bg-gradient-to-r from-slate-950/70 via-slate-700/50 to-slate-100/30' />

        <div className='absolute left-[10%] top-1/3'>
          <div className='mb-3 text-3xl text-white drop-shadow-lg md:text-5xl'>
            {items.heading}
          </div>
          <div className='mt-5'>
            <a
              href={items.siteUrl}
              target='_blank'
              rel='noreferrer'
              className='cursor-pointer rounded bg-white p-2 px-4 text-center text-xs text-black md:text-lg'>
              Know More <i className='fa-solid fa-arrow-right fa-sm'></i>
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <OwlCarousel
      className='owl-theme'
      loop
      autoplay
      autoplayTimeout={4000}
      autoplaySpeed={700}
      dotsSpeed={1500}
      items={1}>
      {bannerInfo.map(bannerContent)}
    </OwlCarousel>
  );
};

export default Banner;
