import React from 'react';

const CourseTemplate = ({course}) => {
  const getCost = (itemcost, index) => {
    return (
      <div
        className='my-2 flex h-16 w-16 items-center justify-center rounded-full bg-[#ff6e65] text-white'
        key={index}>
        {itemcost}
      </div>
    );
  };

  const getCard = (item, index) => {
    return (
      <div
        className='my-5 rounded-xl bg-white text-left shadow hover:shadow-xl'
        key={index}>
        {/* image */}
        <div className='relative'>
          <img
            src={item.img}
            alt='img'
            className='h-full w-full rounded-t-2xl bg-white object-contain sm:h-[340px] lg:h-60 lg:object-cover xl:h-96'
          />

          <div className='absolute left-2 top-5'>
            <ul className='flex flex-row'>
              <li className='mx-1 rounded bg-yellow-400 px-2 text-sm text-white'>
                Code
              </li>
              <li className='mx-1 rounded bg-green-500 px-2 text-sm text-white'>
                Design
              </li>
              <li className='mx-1 rounded bg-sky-600 px-2 text-sm text-white'>
                Free
              </li>
            </ul>
          </div>

          <div className='absolute -bottom-4 right-3'>
            {item.cost.map(getCost)}
          </div>
        </div>

        {/* difficulty & Flexible */}
        <div className='p-3'>
          <div className='my-2 flex flex-row justify-between'>
            <div>
              <i className='fa-solid fa-signal mx-1 text-[#ff6e65]'></i>
              {item.difficulty}
            </div>
            <div>
              <i className='fa-regular fa-clock mx-1 text-[#ff6e65]'></i>
              {item.flexibility}
            </div>
          </div>

          <div>
            <div className='my-3 text-2xl font-medium'>
              <a target='_blank' rel='no noreferrer' href={item.url}>
                {item.title}
              </a>
            </div>
            <div className='my-2 h-20 overflow-hidden text-lg'>
              {item.description}
            </div>
          </div>

          <div className='mx-auto mt-3 w-full rounded-full border border-slate-300'></div>

          <div className='my-2 flex justify-center'>
            <a
              className='text-[#ff6e65]'
              href={item.url}
              target='_blank'
              rel='noreferrer'>
              Know Details <i className='fa-solid fa-arrow-right'></i>
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='mx-auto w-11/12 py-3 lg:w-10/12 xl:w-9/12'>
      <div className='grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3'>
        {course.map(getCard)}
      </div>
    </div>
  );
};

export default CourseTemplate;
