import {collection, getDocs} from 'firebase/firestore';
import React, {useEffect, useState} from 'react';
import {db} from '../../API/firebase.config';
import CourseTemplate from '../../Template/CourseTemplate';

const Youtube = () => {
  const [youtubeInfo, setYoutubeInfo] = useState([]);

  const fetchPost = async () => {
    await getDocs(collection(db, 'youtubeInfo')).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setYoutubeInfo(newData);
    });
  };

  useEffect(() => {
    fetchPost();
  }, []);

  return <CourseTemplate course={youtubeInfo} />;
};

export default Youtube;
