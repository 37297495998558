const bannerInfo = [
  {
    heading: 'Learn What Matters',
    src: require('../bannerImage/banner.jpg'),
    siteUrl: '',
  },
  {
    heading: 'Youtube',
    src: require('../bannerImage/banner1.jpg'),
    siteUrl: 'https://www.youtube.com/channel/UCtFRv9O2AHqOZjjynzrv-xg',
  },
  {
    heading: 'Coursera',
    src: require('../bannerImage/banner2.jpg'),
    siteUrl: 'https://www.coursera.org/',
  },
  {
    heading: 'Great Learning',
    src: require('../bannerImage/banner3.jpg'),
    siteUrl: 'https://www.mygreatlearning.com/',
  },
  {
    heading: 'SimpliLearn',
    src: require('../bannerImage/banner4.jpg'),
    siteUrl: 'https://www.simplilearn.com/',
  },
];

export default bannerInfo;
