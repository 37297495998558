import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAYF14y_uZ00ReiA_F0kz0TwmtpQRDZ3_I',
  authDomain: 'edlearn-fb5e7.firebaseapp.com',
  projectId: 'edlearn-fb5e7',
  storageBucket: 'edlearn-fb5e7.appspot.com',
  messagingSenderId: '292113334805',
  appId: '1:292113334805:web:7be38e8291af598734841c',
  measurementId: 'G-XQZMLE9803',
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
