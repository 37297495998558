import React from 'react';

const Welcome = () => {
  return (
    <div className='mx-auto my-20 w-11/12'>
      <div className='container mx-auto my-10 px-4 text-center'>
        <div className='text-4xl'>
          Welcome to <span className='text-[#ff6e65]'>Ed-Learn</span>
        </div>
        <div className='ml-[45%] mt-2 w-1/12 rounded-full border border-slate-500'></div>
        <div className='ml-[48%] mt-1 w-1/12 rounded-full border border-slate-500'></div>
      </div>

      <div className='grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3'>
        <div className='mx-auto my-3 p-5 text-center'>
          <div className='mx-auto my-5 h-16 w-16 rounded-full border-0 bg-slate-300/40 pt-5 text-blue-800'>
            <i className='fa-solid fa-graduation-cap fa-xl'></i>
          </div>
          <div className='my-4 text-2xl font-semibold'>Trending Courses</div>
          <div className='my-4 text-lg'>
            Trending Courses and Content from different platforms.
          </div>
        </div>

        <div className='mx-auto my-3 p-5 text-center'>
          <div className='mx-auto my-5 h-16 w-16 rounded-full border-0 bg-slate-300/40 pt-5 text-blue-800'>
            <i className='fa-solid fa-users fa-xl'></i>
          </div>
          <div className='my-4 text-2xl font-semibold'>Expert Teachers</div>
          <div className='my-4 text-lg'>
            Expert Teachers from different platforms.
          </div>
        </div>

        <div className='mx-auto my-3 p-5 text-center '>
          <div className='mx-auto my-5 h-16 w-16 rounded-full border-0 bg-slate-300/40 pt-5 text-blue-800'>
            <i className='fa-solid fa-book-open-reader fa-xl'></i>
          </div>
          <div className='my-4 text-2xl font-semibold'>Book & Library</div>
          <div className='my-4 text-lg'>
            Library and Resources from different platforms.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
