import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import App from './App';
import './index.css';

//PWA
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// pages
import MainBody from './components/MainBody/MainBody';
import Courses from './components/Courses/Courses';
import Subject from './components/Subject/Subject';
import About from './components/About/About';
import Resources from './components/Books/Resources';
import NotFound from './components/NoService/NotFound';

//subject pages
import HTML from './components/Subject/Content/HTML';
import CSS from './components/Subject/Content/CSS';
import JavaScript from './components/Subject/Content/JavaScript';

//resources pages
import Books from './components/Books/pages/Books';
import QuestionPaper from './components/Books/pages/QuestionPaper';

//courses pages
import Youtube from './components/Courses/pages/Youtube';
import Certifications from './components/Courses/pages/Certifications';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route index element={<MainBody />} />

      <Route path='/courses' element={<Courses />}>
        <Route index element={<Youtube />} />
        <Route path='/courses/certifications' element={<Certifications />} />
        <Route path='/courses/youtube' element={<Youtube />} />
      </Route>

      <Route path='/subject' element={<Subject />}>
        <Route index element={<HTML />} />
        <Route path='/subject/html' element={<HTML />} />
        <Route path='/subject/css' element={<CSS />} />
        <Route path='/subject/js' element={<JavaScript />} />
        <Route path='/subject/javascript' element={<JavaScript />} />
      </Route>

      <Route path='/aboutus' element={<About />} />

      <Route path='/resources' element={<Resources />}>
        <Route index element={<Books />} />
        <Route path='/resources/books' element={<Books />} />
        <Route path='/resources/questionpaper' element={<QuestionPaper />} />
      </Route>

      <Route path='*' element={<NotFound />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

serviceWorkerRegistration.register();
