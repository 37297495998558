import React from 'react';
import './About.css';
import profileImage from './myImage.jpg';

const About = () => {
  const skillSet = [
    {name: 'HTML', img: 'fa-html5'},
    {name: 'CSS', img: 'fa-css3-alt'},
    {name: 'JavaScript', img: 'fa-js'},
    {name: 'React', img: 'fa-react'},
    {name: 'Tailwind', img: 'fa-phoenix-framework'},
    {name: 'Bootstrap', img: 'fa-bootstrap'},
    {name: 'Linux', img: 'fa-linux'},
    {name: 'Git', img: 'fa-git'},
    {name: 'GitHub', img: 'fa-github'},
    {name: 'C', img: 'fa-c'},
    {name: 'Python', img: 'fa-python'},
    {name: 'Java', img: 'fa-java'},
  ];

  const getSkillBlock = (item, index) => {
    return (
      <div
        className='rounded-xl border-2 border-purple-700 bg-purple-300 p-2 text-center text-lg font-semibold hover:text-xl/[1] hover:shadow-2xl hover:shadow-purple-700'
        key={index}>
        <i className={`fa-brands ${item.img} fa-2xl my-6`} />
        <br />
        {item.name}
      </div>
    );
  };

  return (
    <div className='bg-purple-200/30 py-10'>
      <div className='flex flex-row flex-wrap justify-center'>
        <img
          src={profileImage}
          alt='creatorImage'
          className='m-10 w-[380px] rounded-full shadow-2xl shadow-indigo-900 sm:w-[500px]'
        />
        <div className='mb-5 text-center font-semibold md:my-auto'>
          <div className='text-2xl'>Hello Everyone!</div>
          <div className='text-md'>I'm</div>
          <div className='mainHeading'>Kaushal Gupta</div>
        </div>
      </div>

      <div className='mt-10 p-5'>
        <div className='mb-7 text-center text-5xl font-semibold'>Skills</div>
        <div className='mx-auto grid grid-cols-2 gap-4 sm:grid-cols-3 md:w-9/12 lg:grid-cols-5'>
          {skillSet.map(getSkillBlock)}
        </div>
      </div>
    </div>
  );
};

export default About;
