import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import NoInternet from './components/NoService/NoInternet';

export default function App() {
  if (navigator.onLine) {
    return (
      <div className='no-scrollbar overflow-hidden font-montserrat'>
        <button
          className='fixed bottom-5 right-5 z-30 h-16 w-14 rounded-full border border-white bg-blue-600'
          onClick={() => {
            window.scroll(0, 0);
          }}>
          <i className='fa-solid fa-arrow-up fa-xl text-white'></i>
        </button>
        <Header />
        <div className='mt-14'>
          <Outlet />
        </div>
        <Footer />
      </div>
    );
  }
  else {
    return <NoInternet />
  }

}
